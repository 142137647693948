import { useMutation, useQueries, useQuery, useInfiniteQuery } from "@tanstack/react-query";
import {
  ExportMetaFeedRequest,
  MetricInterface,
  MetricRequest,
  ServerMetricResponseType,
} from "app/screens/Dashboard/interfaces";
import { HttpMethod, makeQueryRequest } from "common/services/APIService";
import { REACT_APP_OCARINA_HOST } from "common/utils/constants";

const fetchMetric = async (metricRequest: MetricRequest, token: string): Promise<ServerMetricResponseType[]> => {
  return await makeQueryRequest(
    `${REACT_APP_OCARINA_HOST}/v1/metrics/`,
    {
      method: HttpMethod.POST,
      body: metricRequest,
    },
    { auth: false },
    { Authorization: token, "Content-Type": "application/json" }
  );
};

const fetchMetricV2 = async (metricRequest: MetricRequest, token: string): Promise<ServerMetricResponseType[]> => {
  return await makeQueryRequest(
    `${REACT_APP_OCARINA_HOST}/v2/metrics/`,
    {
      method: HttpMethod.POST,
      body: metricRequest,
    },
    { auth: false },
    { Authorization: token, "Content-Type": "application/json" }
  );
};

const exportRequest = async (metricRequest: MetricRequest, token: string): Promise<string> => {
  return await makeQueryRequest(
    `${REACT_APP_OCARINA_HOST}/v1/metrics/export/`,
    {
      method: HttpMethod.POST,
      body: metricRequest,
    },
    { auth: false },
    { Authorization: token, "Content-Type": "application/json" }
  );
};

const exportMetaFeedRequest = async (exportMetaFeedRequest: ExportMetaFeedRequest, token: string): Promise<string> => {
  return await makeQueryRequest(
    `${REACT_APP_OCARINA_HOST}/v1/integrations/meta_feed_export/`,
    {
      method: HttpMethod.POST,
      body: exportMetaFeedRequest,
    },
    { auth: false },
    { Authorization: token, "Content-Type": "application/json" }
  );
};

export type MetricMetadataRequest = {
  metric_ids: string[];
};

const fetchMetricMetaData = async (
  metricMetadataRequest: MetricMetadataRequest,
  token: string
): Promise<MetricInterface[]> => {
  return await makeQueryRequest(
    `${REACT_APP_OCARINA_HOST}/v1/metadata/metrics/`,
    {
      method: HttpMethod.POST,
      body: metricMetadataRequest,
    },
    { auth: false },
    { Authorization: token, "Content-Type": "application/json" }
  );
};

const queryKeys = {
  useMetricRequest: (metricRequest: MetricRequest, token: string) => ["metric", JSON.stringify(metricRequest), token],
  useExportRequest: (metricRequest: MetricRequest, token: string) => [
    "export_metric",
    JSON.stringify(metricRequest),
    token,
  ],
  useMetricMetadataRequest: (token: string) => ["metricMetadata", token],
};

const metrics = {
  queryKeys,
  useMetricMetadataRequest: (metricMetadataRequest: MetricMetadataRequest | undefined, token: string | undefined) =>
    useQuery({
      queryKey: queryKeys.useMetricMetadataRequest(token!),
      queryFn: () => fetchMetricMetaData(metricMetadataRequest!, token!),
      enabled: Boolean(metricMetadataRequest) && Boolean(token),
    }),
  useMetricRequest: (metricRequest: MetricRequest | undefined, token: string | undefined) =>
    useQuery({
      queryKey: queryKeys.useMetricRequest(metricRequest!, token!),
      queryFn: () => fetchMetric(metricRequest!, token!),
      enabled: Boolean(metricRequest) && Boolean(token),
    }),
  useMetricRequests: (metricRequests: MetricRequest[], token: string | undefined, enabled = true) =>
    useQueries({
      queries: metricRequests.map((metricRequest) => ({
        queryKey: queryKeys.useMetricRequest(metricRequest, token!),
        queryFn: () => fetchMetric(metricRequest, token!),
        enabled: Boolean(token) && enabled,
      })),
    }),
  usePaginatedMetricRequestV2: (metricRequest: MetricRequest | undefined, token: string | undefined) =>
    useInfiniteQuery({
      queryKey: queryKeys.useMetricRequest(metricRequest!, token!),
      queryFn: async ({ pageParam = 1 }) => {
        const pagination = metricRequest?.pagination
          ? {
              ...metricRequest.pagination,
              page_number: pageParam,
            }
          : undefined;
        const updatedMetricRequest = { ...metricRequest!, pagination };
        const data = await fetchMetricV2(updatedMetricRequest, token!);
        return {
          data,
          pagination,
        };
      },
      getNextPageParam: (lastPage) => {
        const pageNumber = lastPage?.pagination?.page_number;
        return pageNumber + 1;
      },
      enabled: Boolean(metricRequest) && Boolean(token),
    }),
  useMetricRequestV2: (metricRequest: MetricRequest | undefined, token: string | undefined) =>
    useQuery({
      queryKey: queryKeys.useMetricRequest(metricRequest!, token!),
      queryFn: () => fetchMetricV2(metricRequest!, token!),
      enabled: Boolean(metricRequest) && Boolean(token),
      retry: 1,
    }),
  useMetricRequestsV2: (metricRequests: MetricRequest[], token: string | undefined, enabled = true) =>
    useQueries({
      queries: metricRequests.map((metricRequest) => ({
        queryKey: queryKeys.useMetricRequest(metricRequest, token!),
        queryFn: () => fetchMetricV2(metricRequest, token!),
        enabled: Boolean(token) && enabled,
      })),
    }),
  useExportRequest: (onSuccess: (data: string) => void) =>
    useMutation({
      mutationFn: (data: { metricRequest: MetricRequest; token: string }) =>
        exportRequest(data.metricRequest, data.token),
      onSuccess,
    }),
  useExportMetaFeedRequest: (onSuccess: (data: string) => void) =>
    useMutation({
      mutationFn: (data: { exportMetaFeedRequest: ExportMetaFeedRequest; token: string }) =>
        exportMetaFeedRequest(data.exportMetaFeedRequest, data.token),
      onSuccess,
    }),
};

export default metrics;
