import { connectorCategoryLabels } from "app/components/auth/UserOnboarding/hooks/useConnectorsCategoryGroup";
import {
  DashboardAvailability,
  DimensionInterface,
  MetricInterface,
  AnalyticsComponentFilterValueMap,
  FilterType,
  FilterTypeMap,
  Granularity,
  ServerDateRangeType,
  ValueFilter,
  DashboardComparisons,
  DashboardComparisonsEnum,
} from "app/screens/Dashboard/interfaces";
import { ConnectorCategory } from "app/services/APIService/connectorsV2";
import {
  DateRangeKeyType,
  getTimezoneOffsetInMs,
  nextDate,
  firstDateOfNextMonth,
  firstDateOfNextYear,
} from "app/utils/dateutils";
import { addDays, nextMonday, differenceInDays } from "date-fns";
import moment from "moment";

export const getDateRangeFromServerDateRange = (dateRange: ServerDateRangeType): DateRangeKeyType => {
  /*
    This function also replaces the server's timezone with client's timezone without changing the date or time.
    Example, if client's timezone is UTC+5:30:
    2022-07-25T00:00:00+00:00 or 2022-07-25T00:00:00Z
    will be converted to
    2022-07-25T00:00:00+05:30
  */
  const start_date = new Date(dateRange.start_date);
  const end_date = new Date(dateRange.end_date);

  const startDate = new Date(start_date.getTime() + getTimezoneOffsetInMs(start_date));
  const endDate = new Date(end_date.getTime() + getTimezoneOffsetInMs(end_date));

  return {
    startDate,
    endDate,
    key: dateRange.key,
    label: "",
  };
};

export const getServerDateRangeFromDateRange = (dateRange: DateRangeKeyType): ServerDateRangeType => {
  /*
    This function also replaces the client's timezone with utc without changing the date or time.
    Example, if client's timezone is UTC+5:30:
    2022-07-25T00:00:00+05:30
    will be converted to
    2022-07-25T00:00:00Z
  */

  const { startDate, endDate } = dateRange;

  return {
    start_date: new Date(startDate.getTime() - getTimezoneOffsetInMs(startDate)).toISOString(),
    end_date: new Date(endDate.getTime() - getTimezoneOffsetInMs(endDate)).toISOString(),
    key: dateRange.key,
  };
};

export const getDatesListFromRangeAndGranularity = ({
  dateRange,
  granularity,
  relativeDateRange,
}: {
  dateRange: DateRangeKeyType;
  granularity: Granularity;
  relativeDateRange?: DateRangeKeyType;
}): Date[] => {
  /*
   * This function takes in a date range and granularity, and returns
   * all the dates between the range, with difference between the dates
   * decided by the value of {granularity}.
   *
   * relativeDateRange is used to add additional dates in the date list when relative date range
   * is greater than the selected date range
   * */
  const addGranularityToDateFunctionMap: Record<Granularity, (date: Date) => Date> = {
    none: (date: Date) => date,
    day: nextDate,
    week: nextMonday,
    month: firstDateOfNextMonth,
    year: firstDateOfNextYear,
  };
  const { startDate, endDate } = dateRange;
  let currentDate = new Date(startDate);
  const dates = [];
  let extraDates = 0;
  const dateRangeDays = differenceInDays(dateRange.endDate, dateRange.startDate);

  if (relativeDateRange) {
    const relativeDateDays = differenceInDays(relativeDateRange.endDate, relativeDateRange.startDate);
    if (dateRangeDays < relativeDateDays) {
      extraDates = relativeDateDays - dateRangeDays;
    }
  }

  while (addDays(endDate, extraDates).getTime() - currentDate.getTime() >= 0) {
    dates.push(currentDate);
    const addGranularity = addGranularityToDateFunctionMap[granularity];
    currentDate = addGranularity(currentDate);
  }
  return dates;
};

const granularityDateFormatMap: Record<Granularity, string> = {
  none: "DD MMM YYYY",
  day: "DD MMM YYYY",
  week: "DD MMM YYYY",
  month: "MMM YYYY",
  year: "YYYY",
};

export const formatDateForGranularity = ({ date, granularity }: { date: Date; granularity: Granularity }) => {
  return moment(date).format(granularityDateFormatMap[granularity]);
};

export const getSafeSplitName = (split: string) => {
  return split.toLowerCase().replace(/ /g, "_");
};

export const getDimensionsMetadataMap = (
  dimensions: DimensionInterface[]
): Record<DimensionInterface["id"], DimensionInterface> => {
  const dimensionsMetadataMap: Record<DimensionInterface["id"], DimensionInterface> = {};
  dimensions.forEach((dimension) => {
    dimensionsMetadataMap[dimension.id] = dimension;
  });
  return dimensionsMetadataMap;
};

export const getMetricsMetadataMap = (metrics: MetricInterface[]): Record<MetricInterface["id"], MetricInterface> => {
  const metricMetadataMap: Record<MetricInterface["id"], MetricInterface> = {};
  metrics.forEach((metric) => {
    metricMetadataMap[metric.id] = metric;
  });
  return metricMetadataMap;
};

export const getDefaultSelectedFilters = (
  filters: (FilterType | Pick<FilterType, "type" | "default_value">)[]
): AnalyticsComponentFilterValueMap => {
  const defaultSelectedFilters: Partial<AnalyticsComponentFilterValueMap> = {};
  filters.forEach((filter) => {
    defaultSelectedFilters[filter.type] = filter.default_value as any;
  });
  return defaultSelectedFilters as AnalyticsComponentFilterValueMap;
};

export const getComponentFiltersMap = (filters: FilterType[]): FilterTypeMap => {
  const filtersMap: Record<string, FilterType> = {};
  filters.forEach((filter) => {
    filtersMap[filter.type] = filter;
  });
  return filtersMap as FilterTypeMap;
};

interface CustomOption {
  label: string;
  value: string;
}

export const NoneOption: Readonly<CustomOption> = {
  label: "None",
  value: "none",
};

const metricAvailabilityOverrides: Record<string, Record<"dependantSources", ConnectorCategory[]>> = {
  // We want to make a few metrics available even if certain sources are missing. These overrides help us achieve that.
  contribution: {
    dependantSources: [ConnectorCategory.ECOMMERCE],
  },
  contribution_margin: {
    dependantSources: [ConnectorCategory.ECOMMERCE],
  },
};

export const getMissingAndLimitedDataSourcesForMetric = ({
  metric,
  availability,
  metricsMetadataMap,
}: {
  metric: string;
  availability?: DashboardAvailability;
  metricsMetadataMap: Record<MetricInterface["id"], MetricInterface>;
}) => {
  const missingSources: ConnectorCategory[] = [];
  const limitedDataSources: ConnectorCategory[] = [];
  let dependantSources: ConnectorCategory[] = [];
  const dataSourceRowsMap: Record<string, number> = {};
  if (availability) {
    const tableDependencies = metricsMetadataMap[metric].table_dependencies;
    tableDependencies.forEach((tableDependency) => {
      const tableInformationExists = !!availability.data[tableDependency];
      if (tableInformationExists) {
        availability.data[tableDependency].sources.forEach((source) => {
          dataSourceRowsMap[source] = (dataSourceRowsMap[source] || 0) + availability.data[tableDependency].count;
        });
      }
    });
    // Overrides have higher priority than the actual dependant sources
    dependantSources =
      metricAvailabilityOverrides[metric]?.dependantSources || (Object.keys(dataSourceRowsMap) as ConnectorCategory[]);
    dependantSources.forEach((dependantSource) => {
      if (availability.connected_sources[dependantSource]?.length) {
        if (dataSourceRowsMap[dependantSource] === 0) {
          limitedDataSources.push(dependantSource);
        }
      } else {
        missingSources.push(dependantSource);
      }
    });
  }

  return {
    missingSources,
    limitedDataSources,
    dependantSources,
    metric,
  };
};

export const getErrorMessageFromMissingAndLimitedDataSources = ({
  missingSources,
  limitedDataSources,
}: {
  missingSources: ConnectorCategory[];
  limitedDataSources: ConnectorCategory[];
}) => {
  if (missingSources.length > 0) {
    const missingSourcesString = missingSources
      .map((missingSource) => connectorCategoryLabels[missingSource])
      .join(", ");
    const isMultiple = missingSources.length > 1;
    return `Add your ${missingSourcesString} connector${isMultiple ? "s" : ""} to unlock this metric.`;
  } else if (limitedDataSources.length > 0) {
    const limitedDataSourcesString = limitedDataSources
      .map((limitedDataSource) => connectorCategoryLabels[limitedDataSource])
      .join(", ");
    const isMultiple = limitedDataSources.length > 1;
    return `Data from ${limitedDataSourcesString} connector${
      isMultiple ? "s" : ""
    } is insufficient to calculate this metric`;
  }
  return "";
};

export const hasMetricFilter = (dashboardId: string) => {
  const dashboardsThatHaveMetricFilters = ["product-table", "order-table", "product-rankings"];

  return dashboardsThatHaveMetricFilters.includes(dashboardId);
};

export const getMetricsFromMetricFilterValues = (valueFilters: ValueFilter[] | undefined) => {
  const metrics: string[] = [];

  !!valueFilters &&
    valueFilters.forEach((filter) => {
      const isMetricExists = metrics.includes(filter.metric);
      if (!isMetricExists) metrics.push(filter.metric);
    });

  return metrics;
};

export const getDashboardComparisons = (
  comparisonDateRanges: Record<string, DateRangeKeyType>
): DashboardComparisons =>
  Object.keys(comparisonDateRanges).length > 0 ? DashboardComparisonsEnum.ENABLED : DashboardComparisonsEnum.DISABLED;
